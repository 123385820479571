/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

import "./layout.css"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const StyledContainer = styled.div`
 display: flex;
 flex-direction: column;
 border: solid red;

`
const Layout = ({ children }) => {

  return (

    <main>{children}</main>

  
   
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
